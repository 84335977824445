@import "src/scss/variables";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&family=Noto+Serif:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind+Madurai&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300&display=swap');

#home {
    text-align: -webkit-center;
   #header {
       #logo {
           max-width: 300px;
           width: 100%;
       }

       #header-menu {
            align-self: flex-end;
            padding-right: 2.1%;
            margin-bottom: 8px;
            #menu {
                float: right;
                border: none;
                width:100%;
                text-align: right;

                .ant-menu-item {
                    line-height: 30px;
                }

               .ant-menu-item a{
                   color: #000;
                   font-size: 14px;
                   font-weight: 400;
                   font-family: 'Hind Madurai', sans-serif;
               }

               .ant-menu-item:hover, .ant-menu-item-selected {
                   border-bottom: 2px solid #83be42;
               }
            }
            @media (max-width: 576px) { 
               #menu {
                   display: none;
               }
               #drawer-button {
                   float:right;
                   background-color: #83be42;
                   border: 1px solid #83be42;
                   color: #fff;
               }
            }
            @media (min-width: 576px) { 
                #drawer-button {
                    display: none;
                }
            }
        }
    } 

    #about {
        width: 80%;
        #about-image-content {
            @media (min-width: 576px) {
                margin-top: -130px;
            }
            @media (max-width: 576px) {
                margin-top: -20px;
            }
            #about-image {
                max-width: 510px;
                width: 100%;
            }
        }
        
        #about-content {
            text-align:left;
            font-weight: 400;
            font-family: 'Hind Madurai', sans-serif;
            #see-all-products {
                background-color: #fff;
                border: 1px solid #83be42;
                color: #83be42;
                font-weight: 400;
                font-family: 'Hind Madurai', sans-serif;
                height: 50px;
                padding-left: 30px;
                padding-right: 30px;
                font-size: 15px;
            }
            #see-all-products:hover {
                color: #186530;
                background-color: #83be42;
                font-weight: 700;
            }
        }
    }

    .title {
        font-weight: 700;
        font-family: 'Hind Madurai', sans-serif;
    }

    #what-we-do {
        #what-we-do-content {
            #content-image {
                @media (min-width: 576px) {
                    display: inline-flex;
                    margin-left: 100px;
                }
                .we-do-images-content {
                    @media (min-width: 576px) {
                        margin-right: 100px;
                    }
                    .we-do-image {
                        max-width: 257px;
                        margin-top: 50px;
                        width: 100%;
                    }
                    .we-do-title {
                        font-weight: 700;
                        font-family: 'Hind Madurai', sans-serif;
                    }
                    .we-do-content {
                        max-width: 200px;
                        width: 100%;
                        font-weight: 400;
                        font-family: 'Hind Madurai', sans-serif;
                    }
                }
            }
        }
    }

    #contact {
        background-color: #f8f8f8;
        padding-top: 50px;
        padding-bottom: 50px;
        text-align: center; 
        #contact-content {
            display: inline-flex;
            padding-top: 15px;
            @media (max-width: 576px) {
                display: block;
            } 
            .contact-icon {
                font-size: 20px;
            }
        }

        #contact-form {
            margin-top: 20px;
            justify-items: center;
            text-align: -webkit-center;
            #first-contact-form {
                max-width: 674px;
                @media (max-width: 705px) {
                    display: grid;
                }
                .contact-input {
                    height: 50px;
                    border: 1px solid #a7a7a7;
                    border-radius: 5px;
                    background-color: #f8f8f8;
                    @media (min-width: 705px) {
                        width: 325px;
                    }
                    @media (max-width: 705px) {
                        max-width: 325px;
                    }
                }

                .contact-input::placeholder {
                    color: #000000;
                    font-weight: 300;
                    font-family: 'Hind Madurai', sans-serif;
                }

                .ant-row {
                    width: fit-content;
                    @media (max-width: 705px) {
                        width: 100%;
                    }
                }
            }
            .subject-message-content {
                @media (max-width: 705px) {
                    max-width: 325px;
                    text-align: left;
                }
            }

            .full-contact-input {
                border: 1px solid #a7a7a7;
                border-radius: 5px;
                background-color: #f8f8f8;
                @media (min-width: 705px) {
                    width: 674px;
                }
                @media (max-width: 705px) {
                    max-width: 325px;
                }
            }

            .full-contact-input::placeholder{
                color: #000000;
                font-weight: 300;
                font-family: 'Hind Madurai', sans-serif;
            } 

            .ant-input:focus {
                border-color: #83be42;
            }
            .ant-input:hover {
                border-color: #83be42;
            }

            #submit {
                width: 170px;
                font-weight: 700;
                font-family: 'Hind Madurai', sans-serif;
                border-radius: 5px;
                height: 50px;
                padding-left: 30px;
                padding-right: 30px;
                font-size: 16px;
                background-color: #83be42;
                border: none;
                color:#fff;
            }

            #submit:hover {
                color:#186530;
            }
        }
    }

    #map {
        font-size: 20px;
        color: #fff;
    }

    #footer {
        #social-icon {
            .icon {
                padding-right: 25px;
            }
        }
    } 
}
.drawer {
    .ant-drawer-body {
        background-color: #83be42;    
        padding-top: 30px;
    }
    .menu-item {
        padding-top: 20px;
        display: block;
        color: #fff;
        font-family: 'Hind Madurai', sans-serif; 
        font-weight: bold;
        font-size: 15px;
    }
}
