@import "~bootstrap/dist/css/bootstrap-grid.min.css";

@font-face {
  font-family: Rubik;
  src: url("/assets/fonts/Rubik/Rubik-Light.woff");
  font-weight: 300;
}

@font-face {
  font-family: Rubik;
  src: url("/assets/fonts/Rubik/Rubik-Regular.woff");
  font-weight: 400;
}

@font-face {
  font-family: Rubik;
  src: url("/assets/fonts/Rubik/Rubik-Medium.woff");
  font-weight: 500;
}

@font-face {
  font-family: Rubik;
  src: url("/assets/fonts/Rubik/Rubik-Bold.woff");
  font-weight: 700;
}

body {
  margin: 0;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
